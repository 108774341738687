import React from 'react';
import styled from '@emotion/styled';

import mediaqueries from '@styles/media';

import { Icon } from '@types';

const Logo: Icon = ({ fill = 'black' }) => {
  return (
    <LogoContainer>
      <svg
        width={236}
        height={86}
        className="Logo__Desktop"
        x="0"
        y="0"
        viewBox="0 0 200 73"
      >
        <path
          fill={fill}
          className="st2"
          d="M103.3 21.9h4.9v29.2h-4.9zm62.4 0h4.9v29.2h-4.9zm29.2 24.4v-8.8h5v10.7c0 .6-.3 1.1-.8 1.3-5.4 2.9-14.5 2.9-19.4-1.7-3.7-3.5-5-8-4.6-12.9.6-7.1 5.2-12.1 12.1-13.4 3.6-.6 8-.2 11.2 2 .2.1.3.4.3.7v4.5c-.9-.6-2.2-1.4-3.3-1.8-4.3-1.7-8.3-1.6-11.9 1.6-2.6 2.3-3.4 5.4-3.3 8.8.3 5.1 3.7 9 8.5 9.7 2.4.3 4.5-.1 6.2-.7m-62.2 0v-8.8h5v10.7c0 .6-.3 1.1-.8 1.3-5.4 2.9-14.5 2.9-19.4-1.7-3.7-3.5-5-8-4.6-12.9.6-7.1 5.2-12.1 12.1-13.4 3.6-.6 8-.2 11.2 2 .2.1.3.4.3.7v4.5c-.9-.6-2.2-1.4-3.3-1.8-4.3-1.7-8.3-1.6-11.9 1.6-2.6 2.3-3.4 5.4-3.3 8.8.3 5.1 3.7 9 8.5 9.7 2.4.3 4.5-.1 6.2-.7M97.1 23.6v4.9c-.8-.6-1.6-1.1-2.4-1.5-2.1-1.2-4.2-1.6-6.6-1.1-1.6.4-2.6 1.4-2.8 2.9-.3 1.7.3 3 1.9 3.8 1.6.8 3.3 1.3 5 2 1.8.7 3.6 1.5 4.8 3.1 3.6 4.4 1.8 11.3-3.6 13.2-3.8 1.4-8.2 1.5-12.8-1.5v-4.8c3.7 2.7 6.8 3.4 10.2 2.5 1.6-.4 2.7-1.4 2.9-3.1.2-1.8-.5-3.2-2-4-1.5-.7-3.1-1.2-4.6-1.8-1.9-.7-3.7-1.5-5.1-3.1-3.9-4.6-1.6-11.7 4.3-13.2 3.6-1 7.5-.6 10.8 1.7m46.6-1.7v29.2h4.8v-12h11.3v-4.5h-11.3v-8.4h11.3v-4.3z"
        />
        <path
          fill={fill}
          className="st1"
          d="M10.9 34.1c.2-.2.5-.3.8-.5 7.2-4.2 8.7-6.8 8.7-15.1 0-7.5 6.2-13.6 13.7-13.6 2.7 0 5.2.8 7.3 2.1 1.9-.8 3.9-1.3 5.9-1.5-3.4-3.4-8-5.5-13.2-5.5-10.2 0-18.5 8.3-18.6 18.5 0 6.7-.6 7.6-6.3 10.9-.2.1-.3.2-.5.3.4 1.6 1.1 3.2 2.2 4.4"
        />
        <path
          fill={fill}
          className="st1"
          d="M20.5 55.4v-.9c0-8.4-1.5-10.9-8.7-15.1-6.5-3.8-8.7-12.2-5-18.7 1.3-2.3 3.3-4.1 5.5-5.2.3-2 .9-4 1.7-5.9-4.6 1.2-8.8 4.2-11.4 8.7-5.1 8.8-2.1 20.2 6.7 25.3 5.8 3.4 6.3 4.3 6.3 10.9v.6c1.5.5 3.2.6 4.9.3"
        />
        <path
          fill={fill}
          className="st1"
          d="M43.6 57.8c-.3-.1-.5-.3-.8-.4-7.3-4.2-10.2-4.2-17.5 0-6.5 3.7-14.9 1.5-18.6-5-1.3-2.4-1.9-5-1.8-7.4-1.6-1.3-3-2.7-4.2-4.4-1.3 4.6-.8 9.7 1.8 14.2 5.1 8.8 16.4 11.9 25.3 6.8 5.8-3.3 6.8-3.3 12.6 0 .2.1.3.2.5.3 1.2-1.1 2.2-2.6 2.7-4.1"
        />
        <path
          fill={fill}
          className="st1"
          d="M57.3 38.9c-.2.2-.5.3-.8.5-7.2 4.2-8.7 6.8-8.7 15.1 0 7.5-6.2 13.6-13.7 13.6-2.7 0-5.2-.8-7.3-2.1-1.9.8-3.9 1.3-5.9 1.5 3.4 3.4 8 5.5 13.2 5.5 10.2 0 18.5-8.3 18.6-18.5 0-6.7.6-7.6 6.3-10.9.2-.1.3-.2.5-.3-.4-1.6-1.1-3.2-2.2-4.4"
        />
        <path
          fill={fill}
          className="st1"
          d="M47.8 17.6v.9c0 8.4 1.5 10.9 8.7 15.1 6.5 3.8 8.7 12.2 5 18.7-1.3 2.3-3.3 4.1-5.5 5.2-.3 2-.9 4-1.7 5.9 4.6-1.2 8.8-4.2 11.4-8.7 5.1-8.8 2.1-20.2-6.7-25.3-5.8-3.4-6.3-4.3-6.3-10.9v-.6c-1.6-.5-3.3-.6-4.9-.3"
        />
        <path
          fill={fill}
          className="st1"
          d="M24.6 15.2c.3.1.5.3.8.4 7.3 4.2 10.2 4.2 17.5 0 6.5-3.7 14.9-1.5 18.6 5 1.3 2.3 1.9 4.9 1.8 7.4 1.6 1.3 3 2.7 4.2 4.4 1.3-4.6.8-9.7-1.8-14.2-5.1-8.8-16.4-11.9-25.3-6.8-5.8 3.3-6.8 3.3-12.6 0-.2-.1-.3-.2-.5-.3-1.2 1.1-2.2 2.6-2.7 4.1"
        />
      </svg>

      <svg
        width={64}
        height={54}
        className="Logo__Mobile"
        x="0"
        y="0"
        viewBox="0 0 85 73"
      >
        <path
          fill={fill}
          className="st1"
          d="M10.9 34.1c.2-.2.5-.3.8-.5 7.2-4.2 8.7-6.8 8.7-15.1 0-7.5 6.2-13.6 13.7-13.6 2.7 0 5.2.8 7.3 2.1 1.9-.8 3.9-1.3 5.9-1.5-3.4-3.4-8-5.5-13.2-5.5-10.2 0-18.5 8.3-18.6 18.5 0 6.7-.6 7.6-6.3 10.9-.2.1-.3.2-.5.3.4 1.6 1.1 3.2 2.2 4.4"
        />
        <path
          fill={fill}
          className="st1"
          d="M20.5 55.4v-.9c0-8.4-1.5-10.9-8.7-15.1-6.5-3.8-8.7-12.2-5-18.7 1.3-2.3 3.3-4.1 5.5-5.2.3-2 .9-4 1.7-5.9-4.6 1.2-8.8 4.2-11.4 8.7-5.1 8.8-2.1 20.2 6.7 25.3 5.8 3.4 6.3 4.3 6.3 10.9v.6c1.5.5 3.2.6 4.9.3"
        />
        <path
          fill={fill}
          className="st1"
          d="M43.6 57.8c-.3-.1-.5-.3-.8-.4-7.3-4.2-10.2-4.2-17.5 0-6.5 3.7-14.9 1.5-18.6-5-1.3-2.4-1.9-5-1.8-7.4-1.6-1.3-3-2.7-4.2-4.4-1.3 4.6-.8 9.7 1.8 14.2 5.1 8.8 16.4 11.9 25.3 6.8 5.8-3.3 6.8-3.3 12.6 0 .2.1.3.2.5.3 1.2-1.1 2.2-2.6 2.7-4.1"
        />
        <path
          fill={fill}
          className="st1"
          d="M57.3 38.9c-.2.2-.5.3-.8.5-7.2 4.2-8.7 6.8-8.7 15.1 0 7.5-6.2 13.6-13.7 13.6-2.7 0-5.2-.8-7.3-2.1-1.9.8-3.9 1.3-5.9 1.5 3.4 3.4 8 5.5 13.2 5.5 10.2 0 18.5-8.3 18.6-18.5 0-6.7.6-7.6 6.3-10.9.2-.1.3-.2.5-.3-.4-1.6-1.1-3.2-2.2-4.4"
        />
        <path
          fill={fill}
          className="st1"
          d="M47.8 17.6v.9c0 8.4 1.5 10.9 8.7 15.1 6.5 3.8 8.7 12.2 5 18.7-1.3 2.3-3.3 4.1-5.5 5.2-.3 2-.9 4-1.7 5.9 4.6-1.2 8.8-4.2 11.4-8.7 5.1-8.8 2.1-20.2-6.7-25.3-5.8-3.4-6.3-4.3-6.3-10.9v-.6c-1.6-.5-3.3-.6-4.9-.3"
        />
        <path
          fill={fill}
          className="st1"
          d="M24.6 15.2c.3.1.5.3.8.4 7.3 4.2 10.2 4.2 17.5 0 6.5-3.7 14.9-1.5 18.6 5 1.3 2.3 1.9 4.9 1.8 7.4 1.6 1.3 3 2.7 4.2 4.4 1.3-4.6.8-9.7-1.8-14.2-5.1-8.8-16.4-11.9-25.3-6.8-5.8 3.3-6.8 3.3-12.6 0-.2-.1-.3-.2-.5-.3-1.2 1.1-2.2 2.6-2.7 4.1"
        />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}

  .st1 {
    fill: #f05a50;
  }
  .st2 {
    fill: #b2afab;
  }
`;
